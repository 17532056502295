import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { getDocs, query, collection, where, doc, updateDoc, addDoc, getDoc, increment } from 'firebase/firestore'
import Loading from '../components/Loading'
import { db } from '../system/firebase/index'
import Meta from '../components/Meta'
import { fetchWithTimeout, replaceUndefinedValues } from '../helpers/functions'
import UAParser from 'ua-parser-js'
import { FILE_FOLDERS, QR_DESTINATION_TYPES } from '../helpers/constants'

const Redirect = ({ params }) => {
    const [content, setContent] = useState(<Loading color='black.main' text={true} />)

    useEffect(() => {
        async function handleRedirect() {
            const fetchGeoData = async () => {
                try {
                    const ipResponse = await fetchWithTimeout('https://api.ipify.org?format=json', { timeout: 2000 })
                    const { ip } = await ipResponse.json()
                    const geoResponse = await fetchWithTimeout(`https://ipapi.co/${ip}/json/`, { timeout: 2000 })
                    const geoData = await geoResponse.json()
                    return geoData
                } catch (error) {
                    console.error('Error fetching geo data:', error)
                    return null
                }
            }
    
            const getSystemData = () => {
                try {
                    const userAgent = navigator.userAgent
                    const system = UAParser(userAgent)
                    return system
                } catch (error) {
                    console.error('Error getting system data:', error)
                    return null
                }
            }
    
            const fetchAndProcessData = async () => {
                const [geoData, systemData] = await Promise.all([fetchGeoData(), getSystemData()])
                return {
                    geo: geoData,
                    system: systemData,
                }
            }
    
            const docSnap = await getDocs(query(collection(db, 'listings'), where('urlKey', '==', params.redirect)))
            if (!docSnap.docs.length) {
                setContent('Sorry, no B!INK by that URL Key exists.')
                return
            }
            const listing = {
                docId: docSnap.docs[0].id,
                ...docSnap.docs[0].data(),
            }
    
            const data = await fetchAndProcessData()
            const refinedData = replaceUndefinedValues(data)
            const ip = data.geo ? data.geo.ip : null;
            const userAgent = data.system ? data.system.ua : null;
    
            await addDoc(collection(db, 'visits'), {
                listingId: listing.docId,
                timestamp: Date.now(),
                ip: ip,
                userAgent: userAgent,
                ...refinedData,
            })
    
            const listingRef = doc(db, 'listings', listing.docId)
            await updateDoc(listingRef, {
                visits: increment(1),
            })
    
            if (listing.destinationType === QR_DESTINATION_TYPES.IMAGE || listing.destinationType === QR_DESTINATION_TYPES.PDF) {
                getDoc(doc(db, FILE_FOLDERS.FILES, listing.destination)).then((doc) => {
                    if (doc.exists && doc.get('cachedUrl')) {
                        window.location.href = doc.get('cachedUrl')
                    } else {
                        setContent('Sorry, no B!INK redirection for that URL Key exists.')
                    }
                })
            } else {
                window.location.href = listing.destination
            }
        }
    
        handleRedirect()
    }, [params])    

    return (
        <Grid alignItems='center' container={true} justifyContent='center' sx={{ display: 'flex' }} height='100vh' width='100%'>
            <Grid item>
                {content}
            </Grid>
        </Grid>
    )
}

export default Redirect

export const Head = ({ location }) => {
    return <Meta location={location} pageTitle='Redirecting' />
}